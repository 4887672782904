<template>
  <div
    id="my-info-account"
    class="co-flex-col px-6"
  >
    <span class="text-h5 my-4">{{ title }}</span>
    <v-divider />
    <div class="co-w-full co-h24" />
    <el-form
      ref="form"
      :model="frmData"
      :rules="frmRules"
      label-width="100px"
      label-position="right"
    >
      <div
        class="co-flex-col co-justify-center co-items-center my-6"
      >
        <el-upload
          :ref="wdAvatar.name"
          :action="wdAvatar.action"
          :accept="wdAvatar.accept"
          :headers="wdAvatar.headers"
          :auto-upload="wdAvatar.autoUpload"
          :show-file-list="false"
          :before-upload="onAvatarBeforeUpload"
          :on-success="onAvatarSuccess"
          class="avatar-uploader"
        >
          <el-image
            v-if="wdAvatar.value"
            :src="wdAvatar.value"
            fit="cover"
            style="width: 160px; height: 160px; display: block;"
          />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
          />
        </el-upload>
      </div>

      <el-form-item
        label="昵称"
        prop="username"
        required
      >
        <el-input
          v-model="frmData.username"
          maxlength="32"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="真实姓名"
        prop="realname"
        required
      >
        <el-input
          v-model="frmData.realname"
          maxlength="32"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="简介"
        prop="briefIntroduction"
      >
        <el-input
          v-model="frmData.briefIntroduction"
          type="textarea"
          maxlength="128"
          rows="4"
          show-word-limit
        />
      </el-form-item>
      <el-form-item
        label="身份选择"
        prop="identity"
        required
      >
        <el-select
          v-model="frmData.identity"
          placeholder="选择身份"
          style="width: 260px;"
        >
          <el-option
            v-for="(id, index) in idItems"
            :key="index"
            :label="id"
            :value="id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="btnSubmit.loading"
          type="primary"
          style="width: 140px;"
          @click="onSubmit"
        >
          {{ btnSubmit.text }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import user from '@/api/co.user'

  export default {
    name: 'InfoAccount',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        title: '账号信息设置',
        frmData: {
          username: '',
          realname: '',
          briefIntroduction: '',
          signature: '',
          identity: ''
        },
        frmRules: {
          username: [
            { required: true, message: '请输入昵称', trigger: 'blur' },
            { min: 2, max: 32, message: '长度在 2 到 32 个字符', trigger: 'blur' }
          ],
          realname: [
            { required: true, message: '请输入真实姓名', trigger: 'blur' },
            { min: 2, max: 32, message: '长度在 2 到 32 个字符', trigger: 'blur' }
          ],
        },
        idItems: [
          '医务工作者',
          '医学生',
          '其他'
        ],
        wdAvatar: {
          name: 'upload_avatar',
          action: `${api.url.web_util_file_upload}?folder=image`,
          accept: '.jpg,.jpeg,.png,.gif',
          autoUpload: true,
          tip: '只能上传jpg/png/gif文件，且图片不能超过2MB，建议比例为16:9',
          headers: {},
          limit: 1,
          value: '',
          files: []
        },
        btnSubmit: {
          loading: false,
          text: '保存'
        }
      }
    },
    watch: {
      info: {
        handler (val) {
          this.frmData.username = val.username
          this.frmData.realname = val.realname
          this.frmData.briefIntroduction = val.briefIntroduction || ''
          this.frmData.identity = val.identity || ''
          this.wdAvatar.value = val.avatar || ''
        },
        immediate: true
      }
    },
    created () {
      this.configHeaders()
    },
    methods: {
      configHeaders () {
        const obj = user.token.get()
        const headers = {
          token: obj.accessToken,
          uuid: obj.userId
        }
        this.wdAvatar.headers = headers
      },
      onAvatarSuccess (res, file) {
        // console.log({ res, file })
        const resp = file.response || {}
        const data = resp.data || {}
        this.wdAvatar.value = data.fileUrl || ''
      },
      onAvatarBeforeUpload (file) {
        const isLt2M = file.size / 1024 / 1024 <= 2

        if (!isLt2M) {
          this.$notify({
            title: '错误提示',
            message: '上传封面图片大小不能超过 2MB!',
            type: 'error'
          })
        }
        return isLt2M
      },
      getData () {
        const result = {
          username: this.frmData.username,
          realname: this.frmData.realname,
          briefIntroduction: this.frmData.briefIntroduction,
          identity: this.frmData.identity,
          avatar: this.wdAvatar.value
        }

        return result
      },
      updateInfo (data) {
        const info = Object.assign({}, this.info, data)
        user.info.save(info)
      },
      toConfirm (data) {
        this.$emit('confirm', data)
      },
      onSubmit () {
        const me = this
        const data = this.getData()
        // console.log({ data })
        const executing = function () {
          me.btnSubmit.loading = true
        }

        const executed = function (res) {
          me.btnSubmit.loading = false
          if (res.status) {
            me.updateInfo(data)
            me.toConfirm(data)
            me.$notify({
              title: '成功提示',
              message: '账号基本信息设置成功！',
              type: 'success'
            })
          } else {
            const message = `错误：${res.data.code} - ${res.data.desc}`
            me.$notify({
              title: '账号基本信息设置失败',
              message,
              type: 'error'
            })
          }
        }

        user.info.update({
          data,
          executing,
          executed
        })
      }
    }
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #a0aec0;
    border-radius: 100px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #D70D19;
  }
  .avatar-uploader-icon {
    font-size: 32px;
    color: #8c939d;
    width: 160px;
    height: 160x;
    line-height: 160px;
    text-align: center;
  }
  .avatar-user {
    width: 160px;
    height: 160x;
    display: block;
  }
</style>
